import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class Register2Service {

  apiUrl = environment.apiUrl;

  constructor( private _httpClient: HttpClient) {}

  registerPatient(patient: any): Observable<any>{
      return this._httpClient.post(`${this.apiUrl}/patient/auth/register`, patient);

  }
}
