import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatColors } from '@fuse/mat-colors';

@Component({
  selector: 'app-prescription-detail',
  templateUrl: './prescription-detail.component.html',
  styleUrls: ['./prescription-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrescriptionDetailComponent implements OnInit {
  dialogTitle = 'Prescription Detail';
  // presetColors = MatColors.presets;
  prescription: any;
  constructor(private _matDialogRef: MatDialogRef<PrescriptionDetailComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.prescription = _data.prescription;
  }

  ngOnInit(): void {
  }

  closeDialoag(): void {
    this._matDialogRef.close();
  }

}
