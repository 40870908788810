import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { MailConfirmService } from './mail-confirm.service';

@Component({
    selector     : 'mail-confirm',
    templateUrl  : './mail-confirm.component.html',
    styleUrls    : ['./mail-confirm.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class MailConfirmComponent implements OnInit
{
    email: string;
    patientId: number;
    verificationCode: string;
    emailConfirmationDone: boolean;
    loading: boolean;
    errorMessage: string;
    constructor(private _fuseConfigService: FuseConfigService,
                private _activatedRoute: ActivatedRoute,
                private _router: Router,
                private _mailConfirmService: MailConfirmService) {

        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.email = this._activatedRoute.snapshot.queryParamMap.get('email');
        this.patientId = parseInt(this._activatedRoute.snapshot.paramMap.get('id'), 10);
        this.verificationCode = this._activatedRoute.snapshot.queryParamMap.get('verification');
        if (this.email){
            this.emailConfirmationDone = false;
        } else if (this.patientId && this.verificationCode){
            this.loading = true;
            this._mailConfirmService.confirmVerificationCode(this.patientId, this.verificationCode)
                .subscribe((data: any) => {
                    this.loading = false;
                    if (data.success) {
                        this.emailConfirmationDone = true;
                    } else {
                        this.errorMessage = data.error;
                    }
                }, error => console.log(error));
        } else {
            this._router.navigate(['auth/login']);
        }
    }
}
