import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Patient } from '../model/patient.model';
import { FormGroup } from '@angular/forms';
import { ProfileService } from '../service/profile.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-medical-cannabis-authorizationtion',
  templateUrl: './medical-cannabis-authorizationtion.component.html',
  styleUrls: ['./medical-cannabis-authorizationtion.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class MedicalCannabisAuthorizationtionComponent implements OnInit {

  errorMessage = {
      photoUpload:
          'Something wrong happanned while uploading. Please try again',
      incompleteInformation:
          'Your provided information is not complete. Please make sure to fill out all the fields in Personal Information and Identity page'
  };
  message = {
      profileComplete:
          'Congratulations! your profile is complete now and start booking appointments right away'
  };
  patient: Patient;
  pageType: string;
  patientForm: FormGroup;
  genders: any[] = ['Male', 'Female', 'Prefer not to say'];
  answers: string[] = ['Yes', 'No'];
  selectedIndex: number;
  patientId: number;
  selectedProfileImage: File = null;
  selectedPhotoId: File = null;
  profileCompleteMessage: boolean;
  defaultImageUrl: any =
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIcKmZfrmpaa5-IHQHir2njnU_BZnbGpF4qm3Jb-znGmKrtRSU';

  constructor(
      private _profileService: ProfileService,
      private _router: Router,
      private _matDialog: MatDialog,
      private _matSnackBar: MatSnackBar,
      private _changeDetRef: ChangeDetectorRef,
      private _activatedRoute: ActivatedRoute
  ) {
      this.patient = new Patient();
      this.selectedIndex = 0;
  }

  ngOnInit(): void {
      this.patientId = parseInt(localStorage.getItem('patientId'), 10);
      if (this.patientId) {
          this._profileService
              .getPatientDetail(this.patientId)
              .subscribe((data: any) => {
                  if (data) {
                      this.patient = data.patient;
                      this._profileService.updatePatientProfile(this.patient);
                      this.redirectToTab()
                  }
              });
      } else {
          this._router.navigate(['/auth/login']);
      }
  }

  redirectToTab(): void {
    this._activatedRoute.queryParams.subscribe((params) => {
      if(params.tab){
        const tab = params.tab
        switch(tab){
          case 'personal-assessment':
            this.selectedIndex = 0
            break
          case 'medical-disclosure':
            this.selectedIndex = 1
            break
          case 'book-appointment':
            this.selectedIndex = 2
            break
          default:
            this.selectedIndex = 0
        }
      }
    })
  }

  selectedIndexChange(val: number): void {
      this.selectedIndex = val;
  }

  onClickSaveAndContinue(currentTab): void {
    switch(currentTab) {
      case 'personalAssessment':
        if(!this.validatePersonalAssesssment()) {
          return
        }
        this.selectedIndex = 1
        break
      case 'medicalDisclosure':
        if(!this.validateMedicalDisclosure()) {
          return
        }
        this.selectedIndex = 2
        break
      default:
        this.selectedIndex = 0
    }
    this._profileService.savePatientDetail(this.patient).subscribe(
        (data: any) => {
            console.log(data);
        },
        error => console.log(error)
    );
  }

  private validatePersonalAssesssment(): boolean {
    return true
  }

  private validateMedicalDisclosure(): boolean {
    return true
  }

  private displayErrorMessage(message: string): void {
      this._matSnackBar.open(message, null, {
          panelClass: 'mat-snack-danger',
          duration: 5000
      });
  }

  private displayMessage(message: string): void {
      this._matSnackBar.open(message, null, {
          duration: 4000
      });
  }

}
