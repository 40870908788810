import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
import { BookAppointmentService } from '../service/book-appointment.service';
import { Appointment, AppointmentStatus } from '../model/appointment.model';
import { MatSnackBar } from '@angular/material';
import { Nurse } from '../model/nurse.model';
import { ProfileService } from '../service/profile.service';
import { Patient } from '../model/patient.model';

const moment = _moment;

export const MY_FORMATS = {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'LL',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

@Component({
    selector   : 'book-appointment',
    templateUrl: './book-appointment.component.html',
    styleUrls  : ['./book-appointment.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
})
export class BookAppointmentComponent implements OnInit
{
    bookAppointmentForm: FormGroup;
    pickedDate: any;
    pickedTimeSlot: any;
    appointment: Appointment;
    availableNurses: Nurse[];
    patientId: number;
    appointmentExists: boolean;
    loading: boolean;
    nurseResultLoading: boolean;
    patient: Patient;
    availableTime: string[] = ['12 AM - 12.30 AM',
                                '12.30 AM - 1 AM',
                                '1 AM - 1.30 AM',
                                '1.30 AM - 2 AM',
                                '2 AM - 2.30 AM',
                                '2.30 AM - 3 AM',
                                '3 AM - 3.30 AM',
                                '3.30 AM - 4 AM',
                                '4 AM - 4.30 AM',
                                '4.30 AM - 5 AM',
                                '5 AM - 5.30 AM',
                                '5.30 AM - 6 AM',
                                '6 AM - 6.30 AM',
                                '6.30 AM - 7 AM',
                                '7 AM - 7.30 AM',
                                '7.30 AM - 8 AM',
                                '8 AM - 8.30 AM',
                                '8.30 AM - 9 AM',
                                '9 AM - 9.30 AM',
                                '9.30 AM - 10 AM',
                                '10 AM - 10.30 AM',
                                '10.30 AM - 11 AM',
                                '11 AM - 11.30 AM',
                                '11.30 AM - 12 PM',
                                '12 PM - 12.30 PM',
                                '12.30 PM - 1 PM',
                                '1 PM - 1.30 PM',
                                '1.30 PM - 2 PM',
                                '2 PM - 2.30 PM',
                                '2.30 PM - 3 PM',
                                '3 PM - 3.30 PM',
                                '3.30 PM - 4 PM',
                                '4 PM - 4.30 PM',
                                '4.30 PM - 5 PM',
                                '5 PM - 5.30 PM',
                                '5.30 PM - 6 PM',
                                '6 PM - 6.30 PM',
                                '6.30 PM - 7 PM',
                                '7 PM - 7.30 PM',
                                '7.30 PM - 8 PM',
                                '8 PM - 8.30 PM',
                                '8.30 PM - 9 PM',
                                '9 PM - 9.30 PM',
                                '9.30 PM - 10 PM',
                                '10 PM - 10.30 PM',
                                '10.30 PM - 11 PM',
                                '11 PM - 11.30 PM',
                                '11.30 PM - 12 AM'];

    constructor(private _formBuilder: FormBuilder,
                private _bookAppointmentService: BookAppointmentService,
                private _profileService: ProfileService){}

    ngOnInit(): void {
        this.bookAppointmentForm = this._formBuilder.group({
            appointmentDate: ['', Validators.required],
            appointmentTime: ['', Validators.required]
        });
        this.patientId = parseInt(localStorage.getItem('patientId'), 10);
        this.loading = true;
        this._profileService.getPatientDetail(this.patientId)
          .subscribe((data: any) => {
            if (data) {
              this.patient = data.patient;
              if (this.patient && this.patient.profileComplete){
                this._bookAppointmentService.checkAppointmentExists(this.patientId)
                .subscribe( (result: any) => {
                  this.appointmentExists = result.appointmentExists;
                  this.appointment = result.appointment;
                  this.loading = false;
                }, error => console.log(error));
              } else {
                this.loading = false;
              }
            }
          });
    }

    onSubmit(): void {
      this.pickedDate = this.bookAppointmentForm.get('appointmentDate').value._d;
      this.pickedTimeSlot = this.bookAppointmentForm.get('appointmentTime').value;
      const searchQuery = {
        pickedDate: this.pickedDate,
        pickedTimeSlot: this.pickedTimeSlot
      };
      this.nurseResultLoading = true;
      this._bookAppointmentService.searchForAvailableNurses(this.patientId, searchQuery)
        .subscribe( (data: any) => {
          this.availableNurses = data.availableNurses;
          this.nurseResultLoading = false;
        }, error => console.log(error));
    }

    bookAppointment(nurse: any): void {
      const appointment = new Appointment();
      appointment.appointmentDate = this.pickedDate;
      appointment.appointmentTimeSlot = this.pickedTimeSlot;
      appointment.nurseId = nurse.id;
      appointment.appointmentStatus = AppointmentStatus.pending;

      this.loading = true;
      this._bookAppointmentService.bookAppointment(this.patientId, appointment)
        .subscribe( (data: any) => {
          if (data && data.appointment){
            this.appointmentExists = true;
            this.appointment = data.appointment;
            this.loading = false;
          }
        }, error => console.log(error));
    }


}
