import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-patient-agreement',
  templateUrl: './patient-agreement.component.html',
  styleUrls: ['./patient-agreement.component.scss']
})
export class PatientAgreementComponent implements OnInit {
  dialogTitle = 'Patient Agreement';
  privacyStatement: boolean
  constructor(private _matDialogRef: MatDialogRef<PatientAgreementComponent>,
              @Inject(MAT_DIALOG_DATA) private _data: any) {
    this.privacyStatement = _data.privacyStatement
    console.log(this.privacyStatement)
  }

  ngOnInit(): void {}

  closeDialoag(): void {
    this._matDialogRef.close();
  }

}
