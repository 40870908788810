import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Patient } from '../model/patient.model';
import { environment } from 'environments/environment';


@Injectable()
export class ProfileService implements Resolve<any>
{
    apiUrl = environment.apiUrl;
    routeParams: any;
    product: any;
    onProductChanged: BehaviorSubject<any>;

    reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    fileReqHeader = new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    // create a patient behavior subject so other component can listen to this object
    private patientProfile = new BehaviorSubject<Patient>(null);
    patientProfile$ = this.patientProfile.asObservable();
    updatePatientProfile(patient: Patient): void {
        this.patientProfile.next(patient);
    }

    constructor(private _httpClient: HttpClient) {
        this.onProductChanged = new BehaviorSubject({});
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        console.log(this.routeParams); // it is printing the id from the route parameter

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getPatientDetail(this.routeParams.id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getPatientDetail(id: number): Observable<any>
    {
        return this._httpClient.get(`${this.apiUrl}/patient/${id}/getProfileInfo`, {headers: this.reqHeader});

    }

    uploadImage(id: number, image: File, imageType: string): Observable<any> {
        const formData = new FormData();
        formData.append('image', image, image.name);
        formData.append('imageType', imageType);
        return this._httpClient.post(`${this.apiUrl}/patient/${id}/uploadImage`, formData, {headers: this.fileReqHeader});
    }

    uploadProfileImage(id: number, profileImage: File): Observable<any> {
        const formData = new FormData();
        formData.append('profileImage', profileImage, profileImage.name);
        return this._httpClient.post(`${this.apiUrl}/patient/${id}/uploadProfileImage`, formData, {headers: this.fileReqHeader});
    }

    uploadPhotoId(id: number, photoId: File): Observable<any> {
        const formData = new FormData();
        formData.append('photoId', photoId, photoId.name);
        return this._httpClient.post(`${this.apiUrl}/patient/${id}/uploadPhotoId`, formData, {headers: this.fileReqHeader});
    }

    saveProduct(product): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/e-commerce-products/' + product.id, product)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    savePatientDetail(patient: Patient): Observable<any> {
         return this._httpClient.post(`${this.apiUrl}/patient/${patient.id}/saveProfileInfo`, patient, {headers: this.reqHeader});
    }

    addProduct(product): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post('api/e-commerce-products/', product)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
