import { Routes, RouterModule } from '@angular/router';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';
import { JoinConsultationComponent } from './join-consultation/join-consultation.component';
import { ProfileComponent } from './profile/profile.component';
import { NgModule } from '@angular/core';
import { MatButtonModule,
         MatChipsModule,
         MatExpansionModule,
         MatFormFieldModule,
         MatIconModule,
         MatInputModule,
         MatPaginatorModule,
         MatRippleModule,
         MatSelectModule,
         MatSortModule,
         MatSnackBarModule,
         MatTableModule,
         MatTabsModule,
         MatDatepickerModule,
         MatCardModule,
         MatListModule,
         MatProgressSpinnerModule,
         MatCheckboxModule,
         MatRadioModule,
         MatToolbarModule,
         MatGridListModule} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { BookAppointmentService } from './service/book-appointment.service';
import { ProfileService } from './service/profile.service';
import { PrescriptionComponent } from './prescription/prescription.component';
import { PrescriptionService } from './service/prescription.service';
import { PrescriptionDetailComponent } from './prescription/prescription-detail/prescription-detail.component';
import { PatientAgreementComponent } from './profile/patient-agreement/patient-agreement.component';
import { CaptureImageComponent } from './profile/capture-image/capture-image.component';
import { WebcamModule } from 'ngx-webcam';
import { GeneralAppointmentsComponent } from './general-appointments/general-appointments.component';
import { PharmacyBookAppointmentComponent } from './pharmacy-book-appointment/pharmacy-book-appointment.component';
import { PharmacyPrescriptionComponent } from './pharmacy-prescription/pharmacy-prescription.component';
import { MedicalCannabisAuthorizationtionComponent } from './medical-cannabis-authorizationtion/medical-cannabis-authorizationtion.component';

const routes: Routes = [
    // {
    //     path     : 'book-appointment',
    //     component: BookAppointmentComponent
    // },
    {
        path     : 'join-consultation',
        component: JoinConsultationComponent
    },
    {
        path     : 'prescription',
        component: PrescriptionComponent
    },
    {
        path     : 'profile',
        component: ProfileComponent
    },
    {
        path     : 'general-appointments',
        component: GeneralAppointmentsComponent
    },
    {
      path     : 'pharmacy-book-appointment',
      component: PharmacyBookAppointmentComponent
    },
    {
      path     : 'pharmacy-prescription',
      component: PharmacyPrescriptionComponent
    },
    {
        path     : 'medical-cannabis-authorization',
        component: MedicalCannabisAuthorizationtionComponent
    }
];

@NgModule({
    declarations: [
        BookAppointmentComponent,
        JoinConsultationComponent,
        ProfileComponent,
        PrescriptionComponent,
        PrescriptionDetailComponent,
        PatientAgreementComponent,
        CaptureImageComponent,
        GeneralAppointmentsComponent,
        PharmacyBookAppointmentComponent,
        PharmacyPrescriptionComponent,
        MedicalCannabisAuthorizationtionComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCardModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatRadioModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        WebcamModule
    ],
    providers: [
        BookAppointmentService,
        ProfileService,
        PrescriptionService
    ],
    entryComponents: [
        PatientAgreementComponent,
        CaptureImageComponent
    ]
})
export class EcommerceModule
{
}
