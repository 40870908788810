import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
      id       : 'applications',
      title    : 'Dashboard',
      type     : 'group',
      children : [
        {
          id       : 'profile',
          title    : 'Patient Profile',
          type     : 'collapsable',
          children : [
            {
              id        : 'personal-information',
              title     : 'Personal Information',
              type      : 'item',
              url       : 'profile',
              queryParams: {
                tab: 'personal-information'
              },
              exactMatch: false
            },
            {
              id        : 'identity',
              title     : 'Photo ID',
              type      : 'item',
              url       : 'profile',
              queryParams: {
                tab: 'identity'
              },
              exactMatch: false
            }
          ]
      },
      {
        id       : 'book-medical-appointment',
        title    : 'Book Medical Appointment',
        type     : 'collapsable',
        children : [
          {
            id        : 'medical-cannabis-authorization',
            title     : 'Medical Cannabis Authorization',
            type      : 'collapsable',
            children: [
              {
                id        : 'personal-assessment',
                title     : '1. Personal Assessment',
                type      : 'item',
                url       : 'medical-cannabis-authorization',
                queryParams: {
                  tab: 'personal-assessment'
                },
                exactMatch: false
              },
              {
                id        : 'medical-disclosure',
                title     : '2. Medical Disclosure',
                type      : 'item',
                url       : 'medical-cannabis-authorization',
                queryParams: {
                  tab: 'medical-disclosure'
                },
                exactMatch: false
              },
              {
                id        : 'book-appointment',
                title     : '3. Book Appointment',
                type      : 'item',
                url       : 'medical-cannabis-authorization',
                queryParams: {
                  tab: 'book-appointment'
                },
                exactMatch: false
              }
            ]
          },
          {
            id        : 'general-appointment',
            title     : 'General Appointments',
            type      : 'item',
            url       : 'general-appointments',
            exactMatch: true
          }
        ]
      },
      {
        id: 'book-pharmacist-consultation',
        title: 'Book Pharmacist Consultation',
        type: 'collapsable',
        children: [
          {
            id: 'pharmacy-book-appointment',
            title: 'Book Appointment',
            type: 'item',
            url: 'pharmacy-book-appointment',
            exactMatch: true
          }
        ]
      },
      {
        id        : 'prescription',
        title     : 'My Prescriptions',
        type      : 'collapsable',
        children: [
          {
            id: 'pharmacy-prescription',
            title: 'Pharmacy Prescriptions',
            type: 'item',
            url: 'pharmacy-prescription',
            exactMatch: true
          },
          {
            id: 'medical-cannabis-authorization',
            title: 'Medical Cannabis Authorization',
            type: 'item',
            url: 'prescription',
            exactMatch: true
          }
        ]
      },
      {
        id        : 'join-consultation',
        title     : 'Join Consultation',
        type      : 'item',
        url       : 'join-consultation',
        exactMatch: true
      },
    ]
  }
];
