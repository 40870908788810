import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { Login2Service } from './login-2.service';
import { MatSnackBar } from '@angular/material';
import { ProfileService } from '../e-commerce/service/profile.service';
import { BookAppointmentService } from '../e-commerce/service/book-appointment.service';

@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit
{
    loginForm: FormGroup;
    email: string;
    password: string;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _login2Service: Login2Service,
        private _matSnackBar: MatSnackBar,
        private _profileService: ProfileService,
        private _bookAppointmentService: BookAppointmentService
    )
    {
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        
    }

    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
        localStorage.removeItem('token');
        localStorage.removeItem('patientId');
    }

    onSubmitLoginForm(): void {
        const data: any = {
            email: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value
        };
        this.email = this.loginForm.controls.email.value;
        this.password = this.loginForm.controls.password.value;
        this._login2Service.login(data)
            .subscribe((result: any) => {
                if (result.error){
                    this._matSnackBar.open(result.error, null, {duration: 3000, panelClass: 'mat-snack-danger'});
                    return;
                }
                this._profileService.updatePatientProfile(result.patient);
                localStorage.setItem('token', result.token);
                localStorage.setItem('patientId', result.patient.id);
                this._bookAppointmentService.checkAppointmentExists(result.patient.id)
                    .subscribe((response: any) => {
                        if(response && response.appointmentExists) {
                            this._router.navigateByUrl('join-consultation');
                        } else {
                            this._router.navigateByUrl('profile?tab=personal-information');
                        }
                    })
            }, error => console.log(error));
    }
}
