import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class MailConfirmService {

  apiUrl = environment.apiUrl

  constructor(private _httpClient: HttpClient) { }

  confirmVerificationCode(patientId: number, verificationCode: string): Observable<any>{
    return this._httpClient.post(`${this.apiUrl}/patient/${patientId}/auth/verification/${verificationCode}`, {});
  }
}
