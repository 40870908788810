import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class BookAppointmentService {
    apiUrl = environment.apiUrl;
    reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    });

    constructor(private _httpClient: HttpClient){}

    searchForAvailableNurses(id: number, searchQuery: any): Observable<any>{
        return this._httpClient.post(`${this.apiUrl}/patient/${id}/appointment/search`, searchQuery, {headers: this.reqHeader});
    }

    bookAppointment(id: number, appointment: any): Observable<any>{
        return this._httpClient.post(`${this.apiUrl}/patient/${id}/appointment/book`, appointment, {headers: this.reqHeader});
    }

    checkAppointmentExists(id: number): Observable<any>{
        return this._httpClient.get(`${this.apiUrl}/patient/${id}/appointment/check`, {headers: this.reqHeader});
    }

    getActiveAppointment(id: number): Observable<any> {
        return this._httpClient.get(`${this.apiUrl}/patient/${id}/appointment/active`, {headers: this.reqHeader});
    }
}
