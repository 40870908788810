import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatToolbarModule, MatCardModule, MatSnackBar, MatSnackBarModule } from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { Register2Component } from 'app/main/register-2/register-2.component';
import { Register2Service } from './register-2.service';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes = [
    {
        path     : 'auth/register',
        component: Register2Component
    }
];

@NgModule({
    declarations: [
        Register2Component,
        TermsAndConditionsComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,

        FuseSharedModule,
        MatToolbarModule,
        MatCardModule,
        MatSnackBarModule
    ],
    providers: [
        Register2Service
    ],
    entryComponents: [
        TermsAndConditionsComponent
    ]
})
export class Register2Module
{
}
