import { Component, OnInit } from '@angular/core';
import { BookAppointmentService } from '../service/book-appointment.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmationDialogComponent } from 'app/main/shared/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from 'app/main/shared/information-dialog/information-dialog.component';
import { PrescriptionService } from '../service/prescription.service';
import { Prescription } from '../model/prescription.model';
import { Appointment } from '../model/appointment.model';
import { Nurse } from '../model/nurse.model';
import { ProfileService } from '../service/profile.service';
import { Router } from '@angular/router';

@Component({
    selector   : 'join-consultation',
    templateUrl: './join-consultation.component.html',
    styleUrls  : ['./join-consultation.component.scss']
})
export class JoinConsultationComponent implements OnInit {
    showVideo: boolean;
    patientId: number;
    activeAppointment: Appointment;
    nurse: Nurse;
    appointmentExists: boolean;
    loaded: boolean;
    activePrescription: Prescription;

    constructor(private _bookAppointmentService: BookAppointmentService,
                private _sanitizer: DomSanitizer,
                private _matDialog: MatDialog,
                private _prescriptionService: PrescriptionService,
                private _matSnackBar: MatSnackBar,
                private _profileService: ProfileService,
                private _router: Router){}

    ngOnInit(): void {
        this.showVideo = false;
        this.loaded = false;
        this.patientId = parseInt(localStorage.getItem('patientId'), 10);
        if (this.patientId) {
          this._profileService
            .getPatientDetail(this.patientId)
            .subscribe((data: any) => {
                if (data) {
                  this._profileService.updatePatientProfile(data.patient);
                }
            });
        } else {
            this._router.navigate(['/auth/login']);
        }
        this._bookAppointmentService.getActiveAppointment(this.patientId)
          .subscribe((data: any) => {
            this.loaded = true;
            this.activeAppointment = data.appointment;
            this.nurse = data.nurse;
            this.appointmentExists = data.appointmentExists;
          }, error => console.log(error));
    }

    appointmentUrl(): any{
      return this._sanitizer.bypassSecurityTrustResourceUrl(this.activeAppointment.appointmentUrl);
    }

    onClickJoinCall(): void {
      this.showVideo = true;
      // check if the time is valid for calling
    }

    onClickCloseCall(): void {
      const confirmationDialogRef = this._matDialog.open(ConfirmationDialogComponent, {
        width: '600px',
        data: {
          message: 'Are you sure you want to leave the meeting ?'
        }
      });
      confirmationDialogRef.afterClosed().subscribe(result => {
        if (result){
          this.showVideo = false;
          this._matSnackBar.open('Your prescription will be reviewed shortly and you will get a confirmation email with prescripion details', null, {duration: 5000});
        }
      });
    }

    onClickCancel(): void {
      this._matDialog.open(InformationDialogComponent, {
        width: '600px',
        panelClass: 'information-dialog-dialog',
        data: {
          dialogTitle: 'Cancellation Policy',
          dialogContent: 'Cancellation Policy content goes here'
        }
      });
    }
}
