import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { PrescriptionService } from '../service/prescription.service';
import { switchMap } from 'rxjs/operators';

export interface UserData {
  id: string;
  name: string;
  progress: string;
  color: string;
}

/** Constants used to fill up our data base. */
const COLORS: string[] = ['maroon', 'red', 'orange', 'yellow', 'olive', 'green', 'purple',
  'fuchsia', 'lime', 'teal', 'aqua', 'blue', 'navy', 'black', 'gray'];
const NAMES: string[] = ['Maia', 'Asher', 'Olivia', 'Atticus', 'Amelia', 'Jack',
  'Charlotte', 'Theodore', 'Isla', 'Oliver', 'Isabella', 'Jasper',
  'Cora', 'Levi', 'Violet', 'Arthur', 'Mia', 'Thomas', 'Elizabeth'];

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit {
  loading: boolean;
  prescriptions: any[];
  patientId: number;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id', 'created_at', 'dateExpires', 'dateRenewalAllowed', 'prescriptionStatus'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(private _prescriptionService: PrescriptionService) {
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource(this.prescriptions);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.patientId = parseInt(localStorage.getItem('patientId'), 10);
    if (!this.patientId){
      // redirect to login page
    }
    this._prescriptionService.getPrescriptions(this.patientId)
      .subscribe((data: any) => {
        this.prescriptions = data.prescriptions;
        this.prescriptions = [...this.prescriptions];
        this.dataSource.data = this.prescriptions;
      }, error => console.log(error));
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getPrescriptionStatus(id: any): string{
    if (id === 1) { return 'Pending'; }
    else if (id === 2) { return 'Approved'; }
    else if (id === 3) { return 'Rejected'; }
  }
}
