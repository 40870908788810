import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Prescription } from '../model/prescription.model';
import { environment } from 'environments/environment';

@Injectable()
export class PrescriptionService {

  reqHeader = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
  });

  baseApi = environment.apiUrl + '/patient/';

  constructor(private _httpClient: HttpClient) { }

  getPrescriptions(id: number): Observable<any> {
    return this._httpClient.get(this.baseApi + `${id}/prescription`, { headers: this.reqHeader });
  }

  getPrescriptionDetail(prescriptionId: number): Observable<any> {
    return this._httpClient.get(this.baseApi + `prescription/${prescriptionId}`, { headers: this.reqHeader });
  }

  savePrescription(prescription: Prescription): Observable<any> {
    return this._httpClient.post(this.baseApi + `prescription`, prescription, { headers: this.reqHeader });
  }

}
