export class Patient {
    id: number;
    patientNo?: number;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    dateOfBirth?: Date;
    gender?: number;
    email: string;
    phone?: string;
    height?: string;
    weight?: string;
    photoIdUrl?: string;
    imageUrl?: string;
    supended?: boolean;
    password: string;
    emailVerifiedAt?: string;
    emailVerificationLink?: string;
    created_at?: Date;
    updated_at?: Date;
    personalAssessment_anxiety?: boolean;
    personalAssessment_chronicPain?: boolean;
    personalAssessment_insomnia?: boolean;
    personalAssessment_otherReason?: string;
    personalHistory?: string;
    triedToManageSymptoms?: string;
    gainFromCannabisTreatment?: string;
    canadianCitizen?: boolean;
    agreedToPatientTreatmentAgreement?: boolean;
    agreedToShareApplication?: boolean;
    agreedToBeTreatedByHealthCareTeam?: boolean;
    agreedToPrivacyStatement?: boolean;
    profileComplete?: boolean;
}
