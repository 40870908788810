import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { now } from 'moment';

@Component({
  selector: 'app-capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit {
  dialogTitle = 'Capture Image';
  webcamImage: WebcamImage = null;
  private trigger: Subject<void> = new Subject<void>();
  @Output() imageCaptured: EventEmitter<any> = new EventEmitter();
  constructor(private _matDialogRef: MatDialogRef<CaptureImageComponent>) {}

  handleInitError(error: WebcamInitError): void {
    console.log(error);
  }

  handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    const imageName = now() + '.jpeg';
    const imageBlob = this.dataURItoBlob(this.webcamImage.imageAsBase64);
    const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' }); 
    this.imageCaptured.emit(imageFile);
    this.closeDialoag();
  }

  triggerSnapshot(): void {
    this.trigger.next();
  }

  get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  ngOnInit(): void {}

  closeDialoag(): void {
    this._matDialogRef.close();
  }

  onClickCapture(): void {
    this.trigger.next();
  }

  dataURItoBlob(dataURI: any): any {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });    
    return blob;
 }
}
