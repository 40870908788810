import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { Register2Service } from './register-2.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { Router } from '@angular/router';

@Component({
    selector     : 'register-2',
    templateUrl  : './register-2.component.html',
    styleUrls    : ['./register-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Register2Component implements OnInit
{
    registerForm: FormGroup;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _register2Service: Register2Service,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _router: Router
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void
    {
        this.registerForm = this._formBuilder.group({
            name           : ['', Validators.required],
            email          : ['', [Validators.required, Validators.email]],
            password       : ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]],
            acceptTermsAndConditions: ['', [Validators.required]]
        });

        this.registerForm.get('password').valueChanges
            .subscribe(() => {
                this.registerForm.get('passwordConfirm').updateValueAndValidity();
            });
    }

    private termsAndConditionsChecked(): boolean {
        return this.registerForm.get('acceptTermsAndConditions').value;
    }

    onClickRegister(): void {
        if (!this.termsAndConditionsChecked()){
            this._matSnackBar.open('Please accept the terms and conditions!', null, {duration: 3000, panelClass: 'mat-snack-danger'});
            return;
        }
        const patient = {
            email: this.registerForm.get('email').value,
            password: this.registerForm.get('password').value
        };
        this._register2Service.registerPatient(patient)
            .subscribe((data: any) => {
                if (data.error) {
                    this._matSnackBar.open(data.error, null, {duration: 6000, panelClass: 'mat-snack-danger'});
                }
                if (data.message === 'registration_successful'){
                    this._router.navigate(['auth/mail-confirm'], {queryParams: {email: patient.email}});
                }
            }, error => console.log(error));
    }

    onClickTermsAndConditions(): void {
        this._matDialog.open(TermsAndConditionsComponent, {
            panelClass: 'terms-and-conditions-dialog',
            width: '600px',
            height: '600px'
        });
    }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if ( !control.parent || !control )
    {
        return null;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if ( !password || !passwordConfirm )
    {
        return null;
    }

    if ( passwordConfirm.value === '' )
    {
        return null;
    }

    if ( password.value === passwordConfirm.value )
    {
        return null;
    }

    return {'passwordsNotMatching': true};
};
