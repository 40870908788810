import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  dialogTitle = 'Terms and Conditions';
  constructor(private _matDialogRef: MatDialogRef<TermsAndConditionsComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(): void{
    this._matDialogRef.close();
  }

}
