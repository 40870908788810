import {
    Component,
    ViewEncapsulation,
    OnInit,
    ChangeDetectorRef
} from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Patient } from '../model/patient.model';
import { FormGroup } from '@angular/forms';
import { ProfileService } from '../service/profile.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { PatientAgreementComponent } from './patient-agreement/patient-agreement.component';
import { now } from 'moment';
import { CaptureImageComponent } from './capture-image/capture-image.component';

@Component({
    selector: 'profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ProfileComponent implements OnInit {
    errorMessage = {
        photoUpload:
            'Something wrong happanned while uploading. Please try again',
        incompleteInformation:
            'Your provided information is not complete. Please make sure to fill out all the fields in Personal Information and Identity page'
    };
    message = {
        profileComplete:
            'Congratulations! your profile is complete now and start booking appointments right away'
    };
    patient: Patient;
    pageType: string;
    patientForm: FormGroup;
    genders: any[] = ['Male', 'Female', 'Prefer not to say'];
    answers: string[] = ['Yes', 'No'];
    selectedIndex: number;
    patientId: number;
    selectedProfileImage: File = null;
    selectedPhotoId: File = null;
    profileCompleteMessage: boolean;
    defaultImageUrl: any =
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSIcKmZfrmpaa5-IHQHir2njnU_BZnbGpF4qm3Jb-znGmKrtRSU';

    constructor(
        private _profileService: ProfileService,
        private _router: Router,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        private _changeDetRef: ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute
    ) {
        this.patient = new Patient();
        this.selectedIndex = 0;
    }

    ngOnInit(): void {
        this.patientId = parseInt(localStorage.getItem('patientId'), 10);
        if (this.patientId) {
            this._profileService
                .getPatientDetail(this.patientId)
                .subscribe((data: any) => {
                    if (data) {
                        this.patient = data.patient;
                        this._profileService.updatePatientProfile(this.patient);
                        this.redirectToTab()
                    }
                });
        } else {
            this._router.navigate(['/auth/login']);
        }
    }

    redirectToTab(): void {
      this._activatedRoute.queryParams.subscribe((params) => {
        if(params.tab){
          const tab = params.tab
          switch(tab){
            case 'personal-info':
              this.selectedIndex = 0
              break
            case 'identity':
              this.selectedIndex = 1
              break
            default:
              this.selectedIndex = 0
          }
        }
      })
    }

    onProfileImageSelected(event: any): void {
        this.selectedProfileImage = event.target.files[0];
    }

    onPhotoIdSelected(event: any): void {
        this.selectedPhotoId = event.target.files[0];
    }

    onClickSaveAndContinue(currentTab): void {
        switch(currentTab) {
          case 'personalInfo':
            if(!this.validatePersonalInfo()) {
              this._matSnackBar.open('Please complete all required field', null, {duration: 3000, panelClass: 'mat-snack-danger'})
              return
            }
            this.selectedIndex = 1
            break
          case 'personalAssessment':
            if(!this.validatePersonalAssesssment()) {
              return
            }
            this.selectedIndex = 2
            break
          case 'medicalDisclosure':
            if(!this.validateMedicalDisclosure()) {
              return
            }
            this.selectedIndex = 3
            break
          default:
            this.selectedIndex = 0
        }
        this._profileService.savePatientDetail(this.patient).subscribe(
            (data: any) => {
                console.log(data);
            },
            error => console.log(error)
        );
    }

    private validatePersonalInfo(): boolean {
      return this.patient.firstName && this.patient.lastName && this.patient.dateOfBirth
              && this.patient.phone && this.patient.height && this.patient.weight
              && this.patient.gender && this.patient.canadianCitizen && this.patient.agreedToPatientTreatmentAgreement
              && this.patient.agreedToShareApplication && this.patient.agreedToBeTreatedByHealthCareTeam
              && this.patient.agreedToPrivacyStatement
    }

    private validatePersonalAssesssment(): boolean {
      return true
    }

    private validateMedicalDisclosure(): boolean {
      return true
    }

    private validateIdentity(): boolean {
      return this.patient.photoIdUrl && this.patient.photoIdUrl.length > 0
             && this.patient.imageUrl && this.patient.imageUrl.length > 0
    }

    selectedIndexChange(val: number): void {
        this.selectedIndex = val;
    }

    onUploadPhotoId(): void {
        const imageType = 'photoId';
        this._profileService
            .uploadImage(this.patientId, this.selectedPhotoId, imageType)
            .subscribe(
                (data: any) => {
                    const patient = data.patient;
                    if (patient) {
                        this.updateWithNewPhoto(patient, imageType);
                    } else {
                        this.displayErrorMessage(this.errorMessage.photoUpload);
                    }
                },
                error => console.log(error)
            );
    }

    onUploadProfileImage(): void {
        const imageType = 'profileImage';
        this._profileService
            .uploadImage(this.patientId, this.selectedProfileImage, imageType)
            .subscribe(
                (data: any) => {
                    const patient = data.patient;
                    if (patient) {
                        this.updateWithNewPhoto(patient, imageType);
                    } else {
                        this.displayErrorMessage(this.errorMessage.photoUpload);
                    }
                },
                error => console.log(error)
            );
    }

    onClickUpload(imageType: string): void {
        this._profileService
            .uploadImage(this.patientId, this.selectedProfileImage, imageType)
            .subscribe(
                (data: any) => {
                    const patient = data.patient;
                    if (patient) {
                        this.updateWithNewPhoto(patient, imageType);
                    } else {
                        this.displayErrorMessage(this.errorMessage.photoUpload);
                    }
                },
                error => console.log(error)
            );
    }

    onClickPatientTreatmentAndAgreement(
        event: any,
        privacyStatment = false
    ): void {
        event.preventDefault();
        this._matDialog.open(PatientAgreementComponent, {
            panelClass: 'patient-agreement-dialog',
            width: '600px',
            height: '600px',
            data: { privacyStatement: privacyStatment }
        });
    }

    onClickProfileComplete(): void {
        if (!this.validateIsProfileComplete()) {
            this.displayErrorMessage(this.errorMessage.incompleteInformation);
            return;
        }
        this.patient.profileComplete = true;
        this._profileService.savePatientDetail(this.patient).subscribe(
            (data: any) => {
                this.profileCompleteMessage = true;
                this.displayMessage(this.message.profileComplete);
            },
            error => console.log(error)
        );
    }

    onClickCapture(imageType: string): void {
        const captureImageDialogRef = this._matDialog.open(
            CaptureImageComponent,
            {
                panelClass: 'capture-image-dialog',
                width: '600px',
                height: '600px'
            }
        );
        captureImageDialogRef.componentInstance.imageCaptured.subscribe(
            (imageFile: any) => {
                this._profileService
                    .uploadImage(this.patientId, imageFile, imageType)
                    .subscribe((result: any) => {
                        const patient = result.patient;
                        if (patient) {
                            this.updateWithNewPhoto(patient, imageType);
                        } else {
                            this.displayErrorMessage(
                                this.errorMessage.photoUpload
                            );
                        }
                    });
            }
        );
    }

    private validateIsProfileComplete(): boolean {
        return this.validatePersonalInfo() && this.validateIdentity()
    }

    private updateWithNewPhoto(patient: any, imageType: string): void {
        if (imageType === 'photoId') {
            this.patient.photoIdUrl = patient.photoIdUrl + '?d=' + now();
        } else if (imageType === 'profileImage') {
            this.patient.imageUrl = patient.imageUrl + '?d=' + now();
        }
    }

    private displayErrorMessage(message: string): void {
        this._matSnackBar.open(message, null, {
            panelClass: 'mat-snack-danger',
            duration: 5000
        });
    }

    private displayMessage(message: string): void {
        this._matSnackBar.open(message, null, {
            duration: 4000
        });
    }
}
