export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            },
            'PRODUCTS'        : {
                'TITLE': 'Products',
                'BADGE': '25'
            },
            'ORDERS'        : {
                'TITLE': 'Orders',
                'BADGE': '25'
            },
            'PATIENTS'        : {
                'TITLE': 'Patients',
                'BADGE': '25'
            },
            'NURSES'        : {
                'TITLE': 'Nurses',
                'BADGE': '25'
            },
            'APPOINTMENTS'        : {
                'TITLE': 'Appointments',
                'BADGE': '25'
            },
            'PROFILE'        : {
                'TITLE': 'Profile',
                'BADGE': '25'
            }
        }
    }
};
